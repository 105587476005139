exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-development-js": () => import("./../../../src/pages/app-development.js" /* webpackChunkName: "component---src-pages-app-development-js" */),
  "component---src-pages-back-end-development-js": () => import("./../../../src/pages/back-end-development.js" /* webpackChunkName: "component---src-pages-back-end-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-software-development-js": () => import("./../../../src/pages/software-development.js" /* webpackChunkName: "component---src-pages-software-development-js" */),
  "component---src-pages-web-design-js": () => import("./../../../src/pages/web-design.js" /* webpackChunkName: "component---src-pages-web-design-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */)
}

